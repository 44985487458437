// hooks/useAttentionTriggers.js
import { useState, useEffect, useRef } from 'react';


export const DEFAULT_TRIGGER_SETTINGS = {
    EXIT_INTENT: {
        enabled: false,
        type: 'BUBBLE',
        configurations: [{
            urlPath: '*',
            priority: 0,
            message: 'Hei! Löydät täältä hyödyllistä tietoa ja apua.',
            delay: 0,
            optionalOpeningTopic: null
        }]
    },
    SCROLL_DEPTH: {
        enabled: false,
        configurations: [{
            urlPath: '*',
            priority: 0,
            message: 'Etsitkö jotain tiettyä? Täältä löydät apua!',
            threshold: 60,
            optionalOpeningTopic: null
        }]
    },
    INACTIVITY: {
        enabled: false,
        configurations: [{
            urlPath: '*',
            priority: 0,
            message: 'Tarvitsetko apua? Avaa tämä löytääksesi vastauksia.',
            timeout: 45000,
            optionalOpeningTopic: null
        }]
    },
    SESSION_DURATION: {
        enabled: false,
        configurations: [{
            urlPath: '*',
            priority: 0,
            message: 'Voimme auttaa! Katso palvelumme ja yhteystiedot.',
            timeout: 60000,
            optionalOpeningTopic: null
        }]
    },
    REVISIT_TIMEOUT: {
        enabled: false,
        configurations: [{
            urlPath: '*',
            priority: 0,
            message: 'Tervetuloa takaisin! Miten voimme auttaa tänään?',
            duration: 7200000,
            optionalOpeningTopic: null
        }]
    },
    GLOBAL_COOLDOWN: {
        duration: 30000,
    },
    SAME_MESSAGE_DELAY: 24 * 60 * 60 * 1000,
    FIRST_TRIGGER_DELAY: 10000,
    MAX_PER_SESSION_DELAY: 3,
    PERMANENT_DISMISS_DELAY: 7 * 24 * 60 * 60 * 1000
};

const STORAGE_KEYS = {
    LAST_GLOBAL_TRIGGER: 'last_global_trigger',
    TRIGGER_HISTORY: 'trigger_history',
    TRIGGERS_PER_SESSION: 'triggers_per_session',    // NEW: Limit per session
    USER_DISMISSED: 'user_dismissed_triggers',       // NEW: Track user dismissals
    FIRST_VISIT: 'first_visit_date'                 // NEW: Track first visit
};

function initializeFirstVisit() {
    if (!localStorage.getItem(STORAGE_KEYS.FIRST_VISIT)) {
        localStorage.setItem(STORAGE_KEYS.FIRST_VISIT, Date.now().toString());
    }
}

function canTriggerMessage(message, triggerType, settings) {
    const now = Date.now();

    // First visit check
    const firstVisit = localStorage.getItem(STORAGE_KEYS.FIRST_VISIT);
    if (!firstVisit) {
        initializeFirstVisit();
        const timeSinceFirstVisit = now - parseInt(firstVisit);
        if (timeSinceFirstVisit < settings.FIRST_TRIGGER_DELAY) {
            return false;
        }
    }

    // Permanent dismiss check
    const dismissedTriggers = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER_DISMISSED) || '{}');
    if (dismissedTriggers[triggerType] &&
        (now - dismissedTriggers[triggerType]) < settings.PERMANENT_DISMISS_DELAY) {
        return false;
    }

    // Session limit check
    const sessionTriggers = parseInt(sessionStorage.getItem(STORAGE_KEYS.TRIGGERS_PER_SESSION) || '0');
    if (sessionTriggers >= settings.MAX_PER_SESSION_DELAY) {
        return false;
    }

    // Global cooldown check
    const lastGlobalTrigger = localStorage.getItem(STORAGE_KEYS.LAST_GLOBAL_TRIGGER);
    if (lastGlobalTrigger &&
        (now - parseInt(lastGlobalTrigger)) < settings.GLOBAL_COOLDOWN.duration) {
        return false;
    }

    // Same message cooldown check
    const triggerHistory = JSON.parse(localStorage.getItem(STORAGE_KEYS.TRIGGER_HISTORY) || '{}');
    const messageKey = `${triggerType}_${message}`;
    if (triggerHistory[messageKey] &&
        (now - triggerHistory[messageKey]) < settings.SAME_MESSAGE_DELAY) {
        return false;
    }

    return true;
}

function recordTrigger(message, triggerType) {
    const now = Date.now();

    localStorage.setItem(STORAGE_KEYS.LAST_GLOBAL_TRIGGER, now.toString());

    const triggerHistory = JSON.parse(localStorage.getItem(STORAGE_KEYS.TRIGGER_HISTORY) || '{}');
    triggerHistory[`${triggerType}_${message}`] = now;
    localStorage.setItem(STORAGE_KEYS.TRIGGER_HISTORY, JSON.stringify(triggerHistory));

    const sessionTriggers = parseInt(sessionStorage.getItem(STORAGE_KEYS.TRIGGERS_PER_SESSION) || '0');
    sessionStorage.setItem(STORAGE_KEYS.TRIGGERS_PER_SESSION, (sessionTriggers + 1).toString());
}

function handleUserDismiss(triggerType) {
    const dismissedTriggers = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER_DISMISSED) || '{}');
    dismissedTriggers[triggerType] = Date.now();
    localStorage.setItem(STORAGE_KEYS.USER_DISMISSED, JSON.stringify(dismissedTriggers));
}

export const AttentionStyles = () => (
    <style>
        {`
            @keyframes shake {
                10%, 90% { transform: translate3d(-1px, 0, 0); }
                20%, 80% { transform: translate3d(2px, 0, 0); }
                30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
                40%, 60% { transform: translate3d(4px, 0, 0); }
            }

            @keyframes pulse {
                0% { transform: scale(1); }
                50% { transform: scale(1.1); }
                100% { transform: scale(1); }
            }

            .attention-bubble {
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                background: white;
                padding: 8px 12px;
                border-radius: 16px;
                box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                margin-bottom: 8px;
                white-space: nowrap;
                animation: fadeIn 0.3s ease-in-out;
            }

            .attention-bubble:after {
                content: '';
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                border: 8px solid transparent;
                border-top-color: white;
            }

            @keyframes fadeIn {
                from { opacity: 0; transform: translateY(10px) translateX(-50%); }
                to { opacity: 1; transform: translateY(0) translateX(-50%); }
            }
        `}
    </style>
);

export const useAttentionTriggers = (
    isMinimized,
    hasShownAutomatic,
    triggerSettings,
    parentEvents
) => {
    const [attentionState, setAttentionState] = useState({
        isActive: false,
        message: '',
        optionalOpeningTopic: null
    });

    const lastScrollPosition = useRef(0);
    const scrollTimeout = useRef(null);
    const lastTriggerTime = useRef(0);

    // Get parent window events
    const {
        scrollY,
        viewport,
        currentURL,
        exitIntent,
        focusState,
        visibilityState
    } = parentEvents;

    // Merge triggerSettings with default GLOBAL_COOLDOWN
    const settings = triggerSettings ? {
        ...triggerSettings,
        GLOBAL_COOLDOWN: triggerSettings.GLOBAL_COOLDOWN || { duration: 30000 },
        SAME_MESSAGE_DELAY: triggerSettings.SAME_MESSAGE_DELAY || 24 * 60 * 60 * 1000,
        FIRST_TRIGGER_DELAY: triggerSettings.FIRST_TRIGGER_DELAY || 10000,
        MAX_PER_SESSION_DELAY: triggerSettings.MAX_PER_SESSION_DELAY || 3,
        PERMANENT_DISMISS_DELAY: triggerSettings.PERMANENT_DISMISS_DELAY || 7 * 24 * 60 * 60 * 1000
    } : {};


    const handleTrigger = (data, triggerType) => {
        if (!canTriggerMessage(data.message, triggerType, settings)) {
            return;
        }

        setAttentionState(data);
        recordTrigger(data.message, triggerType);
        // add the send message info here
    };

    const getMatchingConfiguration = (triggerType, currentPath) => {
        if (!settings[triggerType]?.configurations) return null;

        const configurations = Array.isArray(settings[triggerType].configurations)
            ? settings[triggerType].configurations
            : Object.values(settings[triggerType].configurations);

        return configurations
            .sort((a, b) => b.priority - a.priority)
            .find(config => {
                if (config.urlPath === '*') return true;
                const pattern = new RegExp(config.urlPath.replace('*', '.*'));
                return pattern.test(currentPath);
            });
    };

    const triggerAttention = (triggerType) => {
        if (!settings[triggerType]?.enabled || !isMinimized || hasShownAutomatic) return;

        const matchingConfig = getMatchingConfiguration(triggerType, currentURL.pathname);
        if (!matchingConfig) return;

        if (!canTriggerMessage(matchingConfig.message, triggerType, settings)) {
            console.debug('Trigger attempted but cooldown active');
            return;
        }

        lastTriggerTime.current = Date.now();

        handleTrigger({
            isActive: true,
            message: matchingConfig.message,
            optionalOpeningTopic: matchingConfig.optionalOpeningTopic
        }, triggerType);

        setTimeout(() => {
            handleTrigger({
                isActive: false,
                message: '',
                optionalOpeningTopic: null
            }, triggerType);
        }, 3000);
    };

    // Exit intent detection
    useEffect(() => {
        if (!settings.EXIT_INTENT) return;

        if (exitIntent) {
            const matchingConfig = getMatchingConfiguration('EXIT_INTENT', currentURL.pathname);
            if (matchingConfig) {
                if (!canTriggerMessage(matchingConfig.message, 'EXIT_INTENT', settings)) {
                    console.debug('Exit intent trigger attempted but cooldown active');
                    return;
                }

                setTimeout(() => {
                    triggerAttention('EXIT_INTENT');
                }, matchingConfig.delay || 0);
            }
        }
    }, [exitIntent, currentURL.pathname]);

    // Scroll behavior detection
    useEffect(() => {
        if (!settings.SCROLL_DEPTH) return;


        const documentHeight = viewport.height;
        const scrollPercentage = (scrollY / (documentHeight - viewport.height)) * 100;

        const matchingConfig = getMatchingConfiguration('SCROLL_DEPTH', currentURL.pathname);

        if (!canTriggerMessage(matchingConfig.message, 'SCROLL_DEPTH', settings)) {
            console.debug('Scroll trigger attempted but cooldown active');
            return;
        }

        if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
        }

        if (matchingConfig && scrollPercentage > matchingConfig.threshold) {
            scrollTimeout.current = setTimeout(() => {
                triggerAttention('SCROLL_DEPTH');
            }, 2000);
        }

        lastScrollPosition.current = scrollY;
    }, [scrollY, viewport, currentURL.pathname]);

    // Inactivity detection
    useEffect(() => {
        if (!settings.INACTIVITY) return;

        let inactivityTimeout;

        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimeout);
            const matchingConfig = getMatchingConfiguration('INACTIVITY', currentURL.pathname);
            if (matchingConfig) {
                inactivityTimeout = setTimeout(() => {
                    if (canTriggerMessage(matchingConfig.message, 'INACTIVITY', settings)) {
                        triggerAttention('INACTIVITY');
                    }
                }, matchingConfig.timeout);
            }
        };

        // Reset timer when focus or visibility changes
        if (focusState && visibilityState) {
            resetInactivityTimer();
        }

        return () => clearTimeout(inactivityTimeout);
    }, [focusState, visibilityState, currentURL.pathname]);

    // Session duration tracking
    useEffect(() => {
        if (!settings.SESSION_DURATION) return;

        const matchingConfig = getMatchingConfiguration('SESSION_DURATION', currentURL.pathname);
        if (matchingConfig) {
            const sessionTimeout = setTimeout(() => {
                if (canTriggerMessage(matchingConfig.message, 'SESSION_DURATION', settings)) {
                    triggerAttention('SESSION_DURATION');
                }
            }, matchingConfig.timeout);

            return () => clearTimeout(sessionTimeout);
        }
    }, [currentURL.pathname]);

    return { attentionState };
};