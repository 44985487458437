import React, { useState, useEffect } from 'react';
import { FaHome, FaEnvelope, FaQuestionCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';

export const FloatingFooter = ({ isVisible, onChange, activeColor = '#c90606', bgColor = "rgb(255 255 255 / 99%)", settings = undefined }) => {
    const [active, setActive] = useState('Koti');

    useEffect(() => {
        if (onChange) {
            onChange(active);
        }
    }, [active, onChange]);

    const floatingFooterStyle = {
        position: 'absolute',
        bottom: 12,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        maxWidth: '360px',
        display: isVisible ? 'flex' : 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 16px',
        boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '18px',
        backgroundColor: bgColor,
        zIndex: 99999,
        border: '1px solid #ddd',
    };

    if (settings) {
        if (settings.hideFooter) {
            return <></>;
        }

        if (settings.useFixedFooter) {
            floatingFooterStyle.maxWidth = '100%';
            floatingFooterStyle.border = 'none';
            floatingFooterStyle.borderRadius = '0';
            floatingFooterStyle.bottom = 0;
        }
    }

    const buttonStyle = (isActive) => ({
        flex: 1,
        textAlign: 'center',
        cursor: 'pointer',
        fontWeight: isActive ? 'bold' : 'normal',
        color: isActive ? activeColor : '#666',
        padding: '6px 0',
        transition: 'color 0.2s ease',
    });

    const iconStyle = (isActive) => ({
        fontSize: '20px',
        marginBottom: '4px',
        color: isActive ? activeColor : '#666',
        transition: 'color 0.2s ease',
    });

    const handleClick = (tab) => {
        setActive(tab);
    };

    return (
        <div className="floating-footer" style={floatingFooterStyle}>
            <motion.div
                style={buttonStyle(active === 'Koti')}
                onClick={() => handleClick('Koti')}
                whileTap={{ scale: 0.95 }}
            >
                <FaHome style={iconStyle(active === 'Koti')} />
                {settings && settings.footerHomeText ? <p style={{ margin: 0 }}>{settings.footerHomeText}</p> : <></>}
            </motion.div>
            {
                settings && settings?.hideContactTab ? <></> : 
                <motion.div
                    style={buttonStyle(active === 'Yhteys')}
                    onClick={() => handleClick('Yhteys')}
                    whileTap={{ scale: 0.95 }}
                >
                    <FaEnvelope style={iconStyle(active === 'Yhteys')} />
                    {settings && settings.footerContactText ? <p style={{ margin: 0 }}>{settings.footerContactText}</p> : <></>}
                </motion.div>
            }
            {
                settings && settings?.hideFaqTab ? <></> : 
                <motion.div
                    style={buttonStyle(active === 'Apu')}
                    onClick={() => handleClick('Apu')}
                    whileTap={{ scale: 0.95 }}
                >
                    <FaQuestionCircle style={iconStyle(active === 'Apu')} />
                    {settings && settings.footerFaqText ? <p style={{ margin: 0 }}>{settings.footerFaqText}</p> : <></>}
                </motion.div>
            }
        </div>
    );
};