// src/AnalyticsContext.jsx
import { createContext, useContext } from 'react';
import { getApiUrl } from '../../config';
import {UAParser} from 'ua-parser-js';

class BotAnalytics {
    constructor(botId, endpoint, options = {}) {
        this.botId = botId;
        this.endpoint = endpoint;
        this.sessionId = this.initializeSessionId();
        this.eventQueue = [];
        this.options = {
            batchSize: options.batchSize || 10, // Number of events per batch
            retryAttempts: options.retryAttempts || 3, // Retry attempts for failed requests
            samplingRate: options.samplingRate || 1.0, // Track 100% of events by default
        };
        this.currentChatId = null;

        // Start periodic batching if batch size is enabled
        if (this.options.batchSize > 1) {
            this.startBatching();
        }
    }

    // Initializes or retrieves the session ID from localStorage
    initializeSessionId() {
        let sessionId = localStorage.getItem('botSessionId');
        if (!sessionId) {
            sessionId = this.generateSessionId();
            localStorage.setItem('botSessionId', sessionId);
        }
        return sessionId;
    }

    generateSessionId() {
        return `session_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    }

    generateChatId() {
        return `chat_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    }

    async startChat(openingMessage = null) {
        this.currentChatId = this.generateChatId();

        const parser = new UAParser();
        const deviceDetails = parser.getResult();

        await this.trackEvent('chat_start', {
            chatId: this.currentChatId,
            openingMessage,
            url: window.location.href,
            sessionId: this.sessionId,
            device: {
                browser: deviceDetails.browser.name,
                os: deviceDetails.os.name,
                device: deviceDetails.device.type || 'Desktop',
            },
        });

        return this.currentChatId;
    }

    async trackChatLoad(url) {
        await this.trackEvent('page_view', {
            url,
            sessionId: this.sessionId,
        });
    }

    async trackChatOpen(openingMessage = null) {
        this.currentChatId = this.generateChatId();
        await this.trackEvent('chat_open', {
            chatId: this.currentChatId,
            openingMessage,
            url: window.location.href,
            sessionId: this.sessionId,
        });
    }

    async trackClick(targetElement, metadata = {}) {
        await this.trackEvent('click', {
            targetElement,
            metadata,
            sessionId: this.sessionId,
        });
    }

    async trackFormSubmit(formData, stepId) {
        if (!this.currentChatId) return;
        await this.trackEvent('form_submit', {
            chatId: this.currentChatId,
            formData,
            stepId,
            sessionId: this.sessionId,
        });
    }

    async trackFaqInteraction(topic, helpful) {
        await this.trackEvent('faq_interaction', {
            topic,
            helpful,
            sessionId: this.sessionId,
        });
    }

    async trackError(errorDetails) {
        await this.trackEvent('error', {
            errorDetails,
            sessionId: this.sessionId,
        });
    }

    async trackTabChange(tabName) {
        await this.trackEvent('tab_change', {
            tabName,
            sessionId: this.sessionId,
        });
    }

    async trackChatClose(reason) {
        if (!this.currentChatId) return;
        await this.trackEvent('chat_close', {
            chatId: this.currentChatId,
            reason,
            sessionId: this.sessionId,
        });
        this.currentChatId = null;
    }

    async trackSessionStart() {
        await this.trackEvent('session_start', {
            sessionId: this.sessionId,
            url: window.location.href,
        });
    }

    async trackEvent(eventType, details = {}) {
        // Sampling logic
        if (Math.random() > this.options.samplingRate) return;

        const event = {
            botId: this.botId,
            eventType,
            timestamp: Date.now(),
            ...details,
        };

        // Queue the event if batching is enabled
        if (this.options.batchSize > 1) {
            this.eventQueue.push(event);
            if (this.eventQueue.length >= this.options.batchSize) {
                await this.flushEventQueue();
            }
        } else {
            // Send immediately if batching is disabled
            await this.sendEvents([event]);
        }
    }

    async trackMessage(message, stepId) {
        if (!this.currentChatId) return;
        
        await this.trackEvent('chat_message', {
            chatId: this.currentChatId,
            message: {
                type: message.type,
                content: message.content,
                stepId
            }
        });
    }



    async endChat() {
        if (!this.currentChatId) return;
        
        await this.trackEvent('chat_end', {
            chatId: this.currentChatId
        });
        this.currentChatId = null;
    }



    async flushEventQueue() {
        if (this.eventQueue.length === 0) return;
        const eventsToSend = [...this.eventQueue];
        this.eventQueue = []; // Clear the queue

        await this.sendEvents(eventsToSend);
    }

    async sendEvents(events) {
        let attempts = 0;
        while (attempts < this.options.retryAttempts) {
            try {
                const response = await fetch(this.endpoint + this.botId, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(events),
                });

                if (!response.ok) {
                    throw new Error('Failed to track events');
                }

                break; // Exit retry loop on success
            } catch (error) {
                console.error('Analytics error:', error);
                attempts++;
                if (attempts >= this.options.retryAttempts) {
                    console.error('Failed to send events after retries:', events);
                }
            }
        }
    }

    startBatching() {
        setInterval(() => this.flushEventQueue(), 5000); // Flush every 5 seconds
    }
}

const AnalyticsContext = createContext(null);

export function AnalyticsProvider({ botId, options, children }) {
    const endpoint = getApiUrl() + "/analytics/";
    const analytics = new BotAnalytics(botId, endpoint, options);
    return (
        <AnalyticsContext.Provider value={analytics}>
            {children}
        </AnalyticsContext.Provider>
    );
}

export function useAnalytics() {
    const analytics = useContext(AnalyticsContext);
    if (!analytics) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider');
    }
    return analytics;
}
