import React, { useState } from 'react';
import { Button, Input, InputGroup, Form, Animation, Message, useToaster } from 'rsuite';
import EmailFillIcon from '@rsuite/icons/EmailFill';
import CloseIcon from '@rsuite/icons/Close';
import CheckIcon from '@rsuite/icons/Check';

function NewsletterSubscribe() {
    const [showInput, setShowInput] = useState(false);
    const [email, setEmail] = useState('');
    const toaster = useToaster();

    const handleSubmit = () => {
        if (email) {
            toaster.push(
                <Message type="success" closable>
                    Kiitos tilauksesta!
                </Message>
            );
            setEmail('');
            setTimeout(() => setShowInput(false), 2000);
        }
    };

    return (
        <div style={{ marginTop: 8 }}>
            <Animation.Collapse in={!showInput}>
                {(props, ref) => (
                    <div {...props} ref={ref}>
                        <Button
                            block
                            onClick={() => setShowInput(true)}
                            style={{
                                padding: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                color: '#ffffff',
                                backgroundImage: 'url(https://golf.fi/wp-content/uploads/2020/09/aloita-2020-2x.jpg)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                fontWeight: 600,
                                height: '100px',
                                fontSize: '24px',
                                textShadow: '1px 1px 3px rgba(0,0,0,0.5)',
                                border: 'none',
                                position: 'relative',
                                overflow: 'hidden'
                            }}
                        >
                            <span style={{ zIndex: 1 }}>Tilaa uutiskirje</span>
                            <EmailFillIcon style={{ fontSize: '24px', zIndex: 1 }} />
                            {/* Dark overlay for better text visibility */}
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0,0,0,0.2)',
                                zIndex: 0
                            }} />
                        </Button>
                    </div>
                )}
            </Animation.Collapse>

            <Animation.Collapse in={showInput}>
                {(props, ref) => (
                    <div {...props} ref={ref}>
                        <Form onSubmit={handleSubmit}>
                            <InputGroup style={{ width: '100%' }}>
                                <Input
                                    type="email"
                                    value={email}
                                    onChange={setEmail}
                                    placeholder="Sähköpostiosoitteesi"
                                    style={{ flex: 1 }}
                                />
                                <InputGroup.Button
                                    color="red"
                                    appearance="primary"
                                    onClick={() => setShowInput(false)}
                                >
                                    <CloseIcon />
                                </InputGroup.Button>
                                <InputGroup.Button
                                    color="green"
                                    appearance="primary"
                                    onClick={handleSubmit}
                                >
                                    <CheckIcon />
                                </InputGroup.Button>
                            </InputGroup>
                        </Form>
                    </div>
                )}
            </Animation.Collapse>
        </div>
    );
}

export default NewsletterSubscribe;