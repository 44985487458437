import React, { useState } from 'react';
import { Form, Button, ButtonToolbar, Schema, Input, SelectPicker } from 'rsuite';
import { getApiUrl } from '../../config';
import { useTranslation } from "react-i18next";
import { motion } from 'framer-motion';

const { StringType } = Schema.Types;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);
// Simple fade-in animation for content
const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.3 }
};

const ContactForm = ({ onGoBack, themeColor, widgetData }) => {
    const { t } = useTranslation();
    const [formValue, setFormValue] = useState({
        subject: '',
        message: '',
        name: '',
        email: '',
        phone: ''
    });
    const [step, setStep] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const form = React.useRef(null);

    const modelStep1 = Schema.Model({
        subject: StringType().isRequired(t('Aihe on pakollinen')), // "Subject is required"
        message: StringType().isRequired(t('Viesti on pakollinen')) // "Message is required"
    });

    const modelStep2 = Schema.Model({
        name: StringType().isRequired(t('Nimi on pakollinen')), // "Name is required"
        email: StringType()
            .isEmail(t('Tarkista sähköpostiosoite')) // "Check the email address"
            .isRequired(t('Sähköposti on pakollinen')), // "Email is required"
        phone: StringType()
    });

    const subjects = [
        { label: t('Yleinen tiedustelu'), value: 'general_inquiry' },
        { label: t('Tarjouspyyntö'), value: 'quote_request' },
        { label: t('Myynti'), value: 'sales' },
        { label: t('Asiakaspalvelu'), value: 'customer_service' },
        { label: t('Reklamaatio'), value: 'complaint' },
        { label: t('Muu asia'), value: 'other' }
    ].map(item => ({ label: item.label, value: item.value }));

    const handleNextStep = () => {
        if (form.current.check()) {
            setStep(2);
        }
    };

    const handlePreviousStep = () => {
        setStep(1);
    };

    const submitForm = async () => {
        const payload = {
            widget_id: widgetData?.bot_id,
            notification_email: widgetData?.notification_emails,
            sender_info: {
                url: window.location.href,
                userAgent: navigator.userAgent,
            },
            ...{ form_data: formValue }
        };

        const response = await fetch(getApiUrl() + '/widget/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            console.error('Failed to submit form:', response.statusText);
        }
    };

    const handleSubmit = () => {
        if (form.current.check()) {
            submitForm();
            setSubmitted(true);
        }
    };

    return (
        <div style={{ padding: 16, background: "white", minHeight: '100%' }}>
            {!submitted ? (
                <motion.div {...fadeIn}>
                    <motion.h3 
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        style={{ marginBottom: 8 }}
                    >
                        {t("Ota yhteyttä")}
                    </motion.h3>
                    <motion.p 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.1 }}
                        style={{
                            fontSize: '14px',
                            color: '#666',
                            marginBottom: 24
                        }}
                    >
                        {step === 1 ? t('Valitse aihe ja kirjoita viestisi') : t('Täytä yhteystietosi, niin olemme sinuun yhteydessä pian.')}
                    </motion.p>

                    <Form
                        ref={form}
                        model={step === 1 ? modelStep1 : modelStep2}
                        formValue={formValue}
                        onChange={setFormValue}
                        onSubmit={step === 1 ? handleNextStep : handleSubmit}
                        fluid
                    >
                        <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.2 }}
                        >
                            {step === 1 ? (
                                <>
                                    <Form.Group controlId="subject">
                                        <Form.ControlLabel>{t("Aihe")}*</Form.ControlLabel>
                                        <SelectPicker
                                            name="subject"
                                            data={subjects}
                                            block
                                            placeholder={t("Valitse aihe")}
                                            value={formValue.subject}
                                            searchable={false}
                                            menuStyle={{ zIndex: 999999999999 }}
                                            style={{ zIndex: 999999999999 }}
                                            container={() => document.body}
                                            onChange={value => setFormValue({ ...formValue, subject: value })}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="message">
                                        <Form.ControlLabel>{t("Viesti")}*</Form.ControlLabel>
                                        <Form.Control
                                            componentClass="textarea"
                                            rows={4}
                                            accepter={Textarea}
                                            name="message"
                                            placeholder={t("Kirjoita viestisi tähän")}
                                        />
                                    </Form.Group>

                                    <ButtonToolbar style={{ marginTop: 24 }}>
                                        <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                                            <Button
                                                appearance="primary"
                                                type="submit"
                                                style={{
                                                    backgroundColor: themeColor,
                                                    borderColor: themeColor
                                                }}
                                            >
                                                {t("Seuraava")}
                                            </Button>
                                        </motion.div>
                                    </ButtonToolbar>
                                </>
                            ) : (
                                <>
                                    <Form.Group controlId="name">
                                        <Form.ControlLabel>{t("Nimi")}*</Form.ControlLabel>
                                        <Form.Control name="name" placeholder={t("Etunimi Sukunimi")} />
                                    </Form.Group>

                                    <Form.Group controlId="email">
                                        <Form.ControlLabel>{t('Sähköposti')}*</Form.ControlLabel>
                                        <Form.Control name="email" type="email" placeholder={t('email@esimerkki.fi')} />
                                    </Form.Group>

                                    <Form.Group controlId="phone">
                                        <Form.ControlLabel>{t('Puhelin')}</Form.ControlLabel>
                                        <Form.Control name="phone" placeholder={t('040 1234567')} />
                                    </Form.Group>

                                    <ButtonToolbar style={{ marginTop: 24 }}>
                                        <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                                            <Button
                                                appearance="primary"
                                                type="submit"
                                                style={{
                                                    backgroundColor: themeColor,
                                                    borderColor: themeColor
                                                }}
                                            >
                                                {t('Lähetä viesti')}
                                            </Button>
                                        </motion.div>
                                        <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                                            <Button
                                                appearance="subtle"
                                                onClick={handlePreviousStep}
                                            >
                                                {t('Takaisin')}
                                            </Button>
                                        </motion.div>
                                    </ButtonToolbar>
                                </>
                            )}
                        </motion.div>
                    </Form>
                </motion.div>
            ) : (
                <motion.div 
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    style={{ textAlign: 'center', padding: '32px 16px' }}
                >
                    <motion.h3 
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                        style={{ marginBottom: 16 }}
                    >
                        {t('Kiitos viestistäsi!')}
                    </motion.h3>
                    <motion.p 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        style={{ marginBottom: 24 }}
                    >
                        {t('Olemme sinuun yhteydessä mahdollisimman pian.')}
                    </motion.p>
                </motion.div>
            )}
        </div>
    );
};

export default ContactForm;