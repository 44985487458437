import React from 'react';
import { useParams } from 'react-router-dom';
import { VirtuaaliApuriWidget } from './VirtuaaliApuriWidget';

export function VirtuaaliApuriTest() {
    const { id } = useParams();

    return (
        <div className="debug-container" style={{ padding: '20px' }}>
            <h1>VirtuaaliApuri Debug Page</h1>
            <p>Testing bot ID: {id}</p>
            
            <VirtuaaliApuriWidget 
                id={id}
                onToggle={(state) => console.log('Widget toggled:', state)}
                onClickOffer={() => console.log('Offer clicked')}
            />
        </div>
    );
}