import React, { useState, useEffect } from 'react';

export function useParentEvents() {
    const [scrollY, setScrollY] = useState(0);
    const [viewport, setViewport] = useState({ width: 0, height: 0 });
    const [currentURL, setCurrentURL] = useState({ pathname: '', search: '', hash: '' });
    const [exitIntent, setExitIntent] = useState(false);
    const [clickData, setClickData] = useState(null);
    const [focusState, setFocusState] = useState(true);
    const [visibilityState, setVisibilityState] = useState(true);

    useEffect(() => {
        const handleMessage = (event) => {
            const { type, ...data } = event.data;
            
            switch (type) {
                case 'SCROLL_POSITION':
                    setScrollY(data.scrollY);
                    break;
                case 'VIEWPORT_DIMENSIONS':
                    setViewport({ width: data.width, height: data.height });
                    break;
                case 'CURRENT_URL':
                    setCurrentURL(data);
                    break;
                case 'EXIT_INTENT':
                    setExitIntent(true);
                    break;
                case 'CLICK_EVENT':
                    setClickData(data);
                    break;
                case 'FOCUS_CHANGE':
                    setFocusState(data.focus);
                    break;
                case 'VISIBILITY_CHANGE':
                    setVisibilityState(data.visible);
                    break;
                default:
                    break;
            }
        };

        // Listen for postMessage instead of CustomEvent
        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    return { 
        scrollY, 
        viewport, 
        currentURL, 
        exitIntent, 
        clickData, 
        focusState, 
        visibilityState 
    };
}